
import { Options, Vue } from "vue-property-decorator";
import EchartComp from "@/components/echartcomp/index.vue";
import * as api from "@/api/safecheck";
@Options({
  components: {
    EchartComp,
  },
})
export default class extends Vue {
  infodata: any = {};
  mounted() {
    this.getReportBillCountAllCheck();
  }
  async getReportBillCountAllCheck() {
    const result: any = await api.reportBillCountAllCheck({
      projectId: this.$store.state.project.projectinfo.projectId,
    });
    if (result.code !== 0) {
      return this.$toast.fail(result.msg);
    }
    this.infodata = result.data;
    this.initChart();
    console.log("this.infodata", this.infodata);
    // this.closePercent = this.infodata.closePercent + '%'
    // this.rate = this.infodata.totalPercent
    // this.percentage = this.infodata.closePercent
  }
  initChart() {
    let option = {
      legend: {
        show: false,
      },
      series: [
        {
          color: ["#3F7CFF", "#5FAD85", "#E5A371"],
          type: "pie",
          startAngle: 0, //起始角度
          radius: ["45", "70"],
          tooltip: {
            show: false,
          },
          label: {
            show: false,
          },
          emphasis: {
            label: {
              show: true,
              formatter: (Object: any) => {
                return (
                  "{top|" +
                  Object.name +
                  "}\n{hr|}\n{bottom|" +
                  (Object.value
                    ? ((Object.value / this.infodata.totalNum) * 100).toFixed(2)
                    : "0") +
                  "%" +
                  "}"
                );
              },
              rich: {
                top: {
                  verticalAlign: "bottom",
                  padding: 4,
                  align: "center",
                  color: "#1E2124",
                  fontWeight: "bold",
                  fontSize: 14,
                  lineHeight: 14,
                },
                hr: {
                  borderColor: "#D0D9E0",
                  width: "100%",
                  borderWidth: 2,
                  height: 0,
                },
                bottom: {
                  verticalAlign: "top",
                  align: "center",
                  color: "#8893A3",
                  fontSize: 10,
                  lineHeight: 14,
                  padding: 4,
                },
              },
            },
          },
          labelLine: {
            length: 20, // 改变标示线的长度
            length2: 30,
            lineStyle: {
              color: "red",
            },
          },
          data: [
            { value: this.infodata.needTodoNum, name: "待整改" },
            { value: this.infodata.completedNum, name: "待审批" },
            { value: this.infodata.closedNum, name: "已审批" },
          ],
        },
        {
          name: "",
          type: "pie",
          radius: ["45", "55"],
          label: {
            show: false,
          },
          emphasis: {
            scale: false,
          },
          tooltip: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          itemStyle: {
            color: "rgba(242, 244, 248, 0.8)",
          },
          data: [
            { value: this.infodata.needTodoNum, name: "待整改" },
            { value: this.infodata.completedNum, name: "待审批" },
            { value: this.infodata.closedNum, name: "已审批" },
          ],
        },
      ],
    };
    (this as any).$refs.charts.setOption(option);
  }
  fGoAdd() {
    this.$router.push("/app/safeCheckSubmit");
  }
}
