
import { Options, Vue, Prop } from "vue-property-decorator";
@Options({})
export default class extends Vue {
  private echarts: any;
  mounted() {
    this.echarts = (this as any).$echarts.init(this.$refs.wrap);
  }
  setOption(option: any) {
    this.echarts.setOption(option);
  }
  beforeUnmount() {
    this.echarts.dispose();
  }
}
